import mapPin from "../../images/footer-icon-map.png";
import phone from "../../images/footer-icon-phone.png";
import email from "../../images/footer-icon-email.png";
import facebook from "../../images/footer-icon-facebook.png";
import linkedin from "../../images/footer-icon-linkedin.png";

export default (theme) => ({
  iconRow: {
    position: "relative",
    width: "100vw",
    maxWidth: "100vw",
    marginLeft: "50%",
    transform: "translateX(-50%)",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
    },
  },
  iconRowInner: {
    display: "block",
    backgroundColor: theme.palette.secondary.main,
    padding: 32,
    textAlign: "left",
    borderBottom: "1px solid #707070",
    [theme.breakpoints.up("md")]: {
      padding: "68px 32px",
      textAlign: "center",
      borderBottom: "none",
      borderLeft: "1px solid #707070",
      flex: "0 0 33.3333%",
      maxWidth: "33.333%",
    },
    "& .gatsby-image-wrapper": {
      fontSize: 0,
      display: "inline-block",
      marginRight: 24,
      verticalAlign: "middle",
      width: 24,
      [theme.breakpoints.up("md")]: {
        width: 46,
      },
    },
    "& span": {
      display: "inline-block",
      verticalAlign: "middle",
      fontWeight: 500,
      color: theme.palette.secondary.contrastText,
      fontSize: 16,
      letterSpacing: "0.016em",
      lineHeight: 1.5,
      paddingRight: 5,
      [theme.breakpoints.up("md")]: {
        fontSize: 24,
        letterSpacing: "0",
      },
    },
  },
  footer: {
    position: "relative",
    backgroundColor: theme.palette.primary.main,
    paddingTop: 55,
    paddingBottom: 50,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      width: "calc(100% + 24px)",
      margin: "0 -12px",
      justifyContent: "space-between",
    },
    [theme.breakpoints.up("md")]: {
      paddingBottom: 30,
      paddingTop: 80,
    },
    "&::before": {
      content: '""',
      backgroundColor: theme.palette.primary.main,
      width: "100vw",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: -1,
    },
    "& p.footer-h": {
      borderBottom: "1px solid " + theme.palette.primary.contrastText,
      fontWeight: "400",
      marginTop: 0,
      color: theme.palette.primary.contrastText,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: "0.150em",
      paddingBottom: 7,
      marginBottom: 30,
      textTransform: "uppercase",
    },
    "& button.back-to-top": {
      fontFamily: theme.headerFont,
      fontSize: 18,
      lineHeight: 2.66,
      borderRadius: "50%",
      backgroundColor: theme.palette.primary.contrastText,
      color: theme.palette.primary.main,
      position: "absolute",
      top: -35,
      right: 0,
      zIndex: 10,
      fontWeight: "700",
      border: "none",
      textAlign: "center",
      width: 70,
      height: 70,
      margin: 0,
      padding: 0,
      opacity: "1 !important",
      visibility: "visible !important",
      letterSpacing: "0.008em",
      transition: "color .3s ease-in-out, background-color .3s ease-in-out",
      "&:hover, &:focus": {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.main,
        "&::before": {
          backgroundColor: theme.palette.primary.main,
          transform: "translateY(-21px) rotate(45deg)",
        },
      },
      "&::before": {
        backgroundColor: theme.palette.primary.contrastText,
        zIndex: 0,
        width: 40,
        height: 40,
        transformOrigin: "50%",
        transform: "rotate(45deg)",
        transition:
          "transform .3s ease-in-out, background-color .3s ease-in-out",
        content: '""',
        display: "block",
        position: "absolute",
        left: "calc(50% - 20px)",
        top: "calc(50% - 20px)",
      },
      "& span": {
        zIndex: 1,
        position: "relative",
      },
    },
    "& .logo-col": {
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "0 12px",
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
        marginBottom: 100,
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 31.25%",
        maxWidth: "31.25%",
      },
      "& a": {
        display: "block",
        margin: "0 auto 60px",
        maxWidth: 212,
        [theme.breakpoints.up("md")]: {
          margin: "0 0 60px",
        },
      },
      "& img.iso-marks": {
        maxWidth: 292,
        width: "100%",
        margin: "0 auto 15px",
        display: "block",
        [theme.breakpoints.up("md")]: {
          margin: "0 0 15px",
        },
      },
    },
    "& .nav-col": {
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "0 12px",
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 13%",
        maxWidth: "13%",
      },
      "& .footer-nav": {
        columnCount: 2,
        marginBottom: 56,
        [theme.breakpoints.up("sm")]: {
          columnCount: 4,
        },
        [theme.breakpoints.up("md")]: {
          columnCount: 1,
        },
        "& a": {
          display: "block",
          marginBottom: 24,
          fontSize: 14,
          lineHeight: 1.72,
          letterSpacing: "0.032em",
          color: theme.palette.primary.contrastText,
          transition: "opacity .3s ease-in-out",
          "&:hover, &:focus": {
            color: theme.palette.primary.contrastText,
            opacity: 0.8,
          },
          [theme.breakpoints.up("lg")]: {
            marginBottom: 4,
          },
        },
      },
    },
    "& .contact-col": {
      marginBottom: 46,
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "0 12px",
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 18.75%",
        maxWidth: "18.75%",
      },
      "& .address, & .phone, & .email": {
        display: "block",
        paddingLeft: 35,
        marginTop: 0,
        fontSize: 16,
        lineHeight: "24px",
        letterSpacing: "0.032em",
        marginBottom: 22,
        backgroundPosition: "top 4px left",
        backgroundRepeat: "no-repeat",
        color: theme.palette.primary.contrastText,
        textDecoration: "none",
      },
      "& .address": {
        fontSize: 14,
        backgroundImage: "url(" + mapPin + ")",
      },
      "& .phone": {
        backgroundImage: "url(" + phone + ")",
        transition: "opacity .3s ease-in-out",
        "&:hover, &:focus": {
          color: theme.palette.primary.contrastText,
          opacity: 0.8,
        },
      },
      "& .email": {
        backgroundImage: "url(" + email + ")",
        transition: "opacity .3s ease-in-out",
        "&:hover, &:focus": {
          color: theme.palette.primary.contrastText,
          opacity: 0.8,
        },
      },
    },
    "& .social-col": {
      marginBottom: 50,
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 50%",
        maxWidth: "50%",
        padding: "0 12px",
        marginBottom: 0,
      },
      [theme.breakpoints.up("md")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 18.75%",
        maxWidth: "18.75%",
      },
      "& a": {
        backgroundPosition: "center right",
        height: 26,
        width: 212,
        lineHeight: "26px",
        fontSize: 14,
        letterSpacing: "0.032em",
        color: theme.palette.primary.contrastText,
        display: "block",
        position: "relative",
        marginBottom: 12,
        backgroundRepeat: "no-repeat",
        transition: "opacity .3s ease-in-out",
        [theme.breakpoints.up("sm")]: {
          width: "100%",
          paddingRight: 50,
        },
        "&:hover, &:focus": {
          color: theme.palette.primary.contrastText,
          opacity: 0.8,
        },
        "& svg": {
          position: "absolute",
          width: 26,
          height: 26,
          top: 0,
          right: 0,
          "& path": {
            fill: theme.palette.secondary.main,
          },
        },
      },
    },
    "& .credit-col": {
      color: theme.palette.primary.contrastText,
      textTransform: "uppercase",
      fontSize: 10,
      letterSpacing: "0.152em",
      textAlign: "center",
      lineHeight: 1.6,
      marginBottom: 24,
      [theme.breakpoints.up("sm")]: {
        marginTop: 50,
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "0 12px",
      },
      [theme.breakpoints.up("md")]: {
        marginTop: 0,
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 75%",
        maxWidth: "75%",
        textAlign: "left",
      },
      "& span": {
        display: "block",
        marginBottom: 16,
        [theme.breakpoints.up("md")]: {
          marginBottom: 0,
          display: "inline-block",
          marginRight: 20,
          "&::after": {
            content: '"|"',
            marginLeft: 20,
          },
          "&:nth-of-type(3)": {
            marginRight: 0,
            "&::after": {
              display: "none",
            },
          },
        },
      },
      "& a": {
        color: theme.palette.primary.contrastText,
        textDecoration: "underline",
      },
    },
    "& .access-nav-col": {
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        flex: "0 0 100%",
        maxWidth: "100%",
        padding: "0 12px",
      },
      [theme.breakpoints.up("lg")]: {
        flex: "0 0 25%",
        maxWidth: "25%",
        textAlign: "right",
        marginRight: -30,
        whiteSpace: "nowrap",
      },
      "& a": {
        display: "block",
        fontSize: 10,
        letterSpacing: "0.032em",
        lineHeight: 2.4,
        marginBottom: 32,
        textDecoration: "underline",
        color: theme.palette.primary.contrastText,
        textTransform: "uppercase",
        transition: "opacity .3s ease-in-out",
        "&:hover, &:focus": {
          color: theme.palette.primary.contrastText,
          opacity: 0.8,
        },
        [theme.breakpoints.up("md")]: {
          display: "inline-block",
          padding: "0 15px",
          marginBottom: 0,
        },
        [theme.breakpoints.up("lg")]: {
          padding: "0 30px 0 0",
        },
      },
    },
  },
  footerTheme: {
    ...theme.footerTheme,
  },
});
