import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import styles from './styles';

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings
});

const mapDispatchToProps = (dispatch) => ({
});

const connector = connect(mapStateToProps, mapDispatchToProps);

class Toast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      show: false
    }

    this.hide = this.hide.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.wishlist.length < this.props.wishlist.length) {
      this.show();
      this.setState({
        text: 'Product added to Quote Builder'
      })
    }

    if (prevProps.wishlist.length > this.props.wishlist.length) {
      this.show();
      this.setState({
        text: 'Product removed from Quote Builder'
      })
    }
  }

  show() {
    this.setState({
      show: true
    }, () => this.hide())
  }

  hide() {
    setTimeout(() => {
      this.setState({
        show: false
      })
    }, 5000);
  }

  render() {
    return <div className={`${this.props.classes.toast} ${this.state.show ? 'show' : ''}`}>{ this.state.text }</div>;
  }
}

export default withStyles(styles)(connector(Toast));