import React from "react";
import Link from "../Link";
import ReactHtmlParser from "react-html-parser";
import Img from "gatsby-image";

function DesktopNav(props) {
  return (
    <div className="desktop-nav">
      {props.menuItems.map((section) => (
        <div className="nav-item" key={section.id}>
          {section.childItems.nodes.length === 0 && (
            <Link key={section.id} to={section.path}>
              {ReactHtmlParser(section.label)}
            </Link>
          )}
          {section.childItems.nodes.length > 0 && (
            <>
              <a key={section.id} href="##">
                {" "}
                {ReactHtmlParser(section.label)}{" "}
              </a>
              {section.cssClasses.indexOf("product-nav") !== -1 && (
                <div className="sub-menu product-sub-nav">
                  {section.childItems.nodes.map((subSection) => (
                    <React.Fragment key={subSection.id}>
                      {subSection.childItems.nodes.length === 0 && (
                        <div className="prod-cat-col">
                          <Link
                            className={
                              "full-link " + subSection.cssClasses.join(" ")
                            }
                            key={subSection.id}
                            to={subSection.path}
                          >
                            <span className="content">
                              <span>Visit All</span>
                              <span>{ReactHtmlParser(subSection.label)}</span>
                            </span>
                            {subSection.MenuItemImage.image !== null && (
                              <span className="image">
                                <Img
                                  fluid={
                                    subSection.MenuItemImage.image.localFile
                                      ?.childImageSharp?.fluid
                                  }
                                />
                              </span>
                            )}
                          </Link>
                        </div>
                      )}
                      {subSection.childItems.nodes.length !== 0 && (
                        <>
                          <div className="prod-cat-col">
                            <Link
                              className={
                                "full-link " + subSection.cssClasses.join(" ")
                              }
                              key={subSection.id}
                              to={subSection.path}
                            >
                              <span className="content">
                                <span>View All</span>
                                <span>{ReactHtmlParser(subSection.label)}</span>
                              </span>
                              {subSection.MenuItemImage.image !== null && (
                                <span className="image">
                                  <Img
                                    fluid={
                                      subSection.MenuItemImage.image.localFile
                                        .childImageSharp.fluid
                                    }
                                  />
                                </span>
                              )}
                            </Link>
                            <div className="sub-nav">
                              {subSection.childItems.nodes.map(
                                (subSubSection) => (
                                  <Link
                                    key={subSubSection.id}
                                    to={subSubSection.path}
                                  >
                                    {ReactHtmlParser(subSubSection.label)}
                                  </Link>
                                )
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </React.Fragment>
                  ))}
                </div>
              )}
              {section.cssClasses.indexOf("product-nav") === -1 && (
                <div className="sub-menu sub-nav">
                  <div className="overview-col">
                    <Link to={section.path} className="full-link overview">
                      <span className="content">
                        <span>Overview</span>
                        <span>{ReactHtmlParser(section.label)}</span>
                      </span>
                      {section.MenuItemImage.image !== null && (
                        <span className="image">
                          <Img
                            fluid={
                              section.MenuItemImage.image.localFile
                                .childImageSharp.fluid
                            }
                          />
                        </span>
                      )}
                    </Link>
                  </div>
                  <div className="nav-col sub-nav">
                    {section.childItems.nodes.map((subSection) => (
                      <Link key={subSection.id} to={subSection.path}>
                        {ReactHtmlParser(subSection.label)}
                      </Link>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default DesktopNav;
