const trackEvent = (event) => {
    if(typeof window !== `undefined`) {
        let data = window.dataLayer;
        if(data) data.push(event);
    }
};

export const trackRoomBookingClick = () => {
    trackEvent({
        event: 'custom-actions',
        customType: 'room-booking',
        customAction: 'start',
        customValue: ''
    });
};

export const trackTableBookingClick = () => {
    trackEvent({
        event: 'custom-actions',
        customType: 'table-booking',
        customAction: 'start',
        customValue: ''
    });
};

export const trackFileView = (file) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'file-view',
        customAction: file,
        customValue: ''
    });
};

export const trackOutboundLink = (link) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'outbound-link',
        customAction: link,
        customValue: ''
    });
};

export const trackSubscribe = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'subscribe',
        customAction: url,
        customValue: ''
    });
};

export const trackContactForm = (url) => {
    trackEvent({
        event: 'custom-actions',
        customType: 'contact-form',
        customAction: url,
        customValue: ''
    });
};
