export default theme => ({
   toast: {
      position: 'fixed',
      bottom: 12,
      left: 12,
      backgroundColor: theme.palette.primary.main,
      borderRadius: 10,
      boxShadow: '0 4px 16px rgba(0,0,0,0.16)',
      fontSize: 18,
      padding: '12px 24px',
      fontWeight: '300',
      fontFamily: theme.bodyFont,
      color: theme.palette.primary.contrastText,
      opacity: 0,
      transition: 'opacity .3s ease-in-out, bottom .3s ease-in',
      zIndex: 10,
      '&.show': {
         opacity: 1,
         bottom: 24,
      }
   }
});