export const SET_LAYOUT = 'SET_LAYOUT';
export const SET_SORT = 'SET_SORT';
export const SET_VISIBLE_ITEMS = 'SET_VISIBLE_ITEMS';
export const SET_FILTER = 'SET_FILTER';
export const WISHLIST_ADD_ITEM = 'WISHLIST_ADD_ITEM';
export const WISHLIST_REMOVE_ITEM = 'WISHLIST_REMOVE_ITEM';
export const WISHLIST_SET_QUANTITY = 'WISHLIST_SET_QUANTITY';
export const WISHLIST_TOGGLE_ITEM = 'WISHLIST_TOGGLE_ITEM';
export const OPEN = 'OPEN';
export const CLOSE = 'CLOSE';
export const SET_PAGE = 'SET_PAGE';
