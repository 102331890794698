import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Link from '../Link';
import ReactHtmlParser from "react-html-parser";

export default function SubNav() {

    const data = useStaticQuery(graphql`
        query SubFooterItems {
            allWpMenu(filter: {locations: {in: FOOTER_SUB_MENU}}) {
            edges {
                node {
                id
                name
                locations
                slug
                menuItems {
                    nodes {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                    childItems {
                        nodes {
                        id
                        title
                        url
                        path
                        label
                        target
                        order
                        }
                    }
                    }
                }
                }
            }
            }
        }
      `);
  
    return (
        <>
            {data.allWpMenu.edges[0].node.menuItems.nodes.map(section => (
                <Link
                    key={section.id}
                    to={section.path}
                >
                    {ReactHtmlParser(section.label)}
                </Link>
            ))}
        </>
    );
  }